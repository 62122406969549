@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-950: theme('colors.indigo.950');
    --primary-900: theme('colors.indigo.900');
    --primary-800: theme('colors.indigo.800');
    --primary-700: theme('colors.indigo.700');
    --primary-600: theme('colors.indigo.600');
    --primary-500: theme('colors.indigo.500');
    --primary-400: theme('colors.indigo.400');
    --primary-300: theme('colors.indigo.300');
    --primary-200: theme('colors.indigo.200');
    --primary-100: theme('colors.indigo.100');
    --primary-50: theme('colors.indigo.50');

    --accent-950: theme('colors.pink.950');
    --accent-900: theme('colors.pink.900');
    --accent-800: theme('colors.pink.800');
    --accent-700: theme('colors.pink.700');
    --accent-600: theme('colors.pink.600');
    --accent-500: theme('colors.pink.500');
    --accent-400: theme('colors.pink.400');
    --accent-300: theme('colors.pink.300');
    --accent-200: theme('colors.pink.200');
    --accent-100: theme('colors.pink.100');
    --accent-50: theme('colors.pink.50');

    --body-950: theme('colors.gray.950');
    --body-900: theme('colors.gray.900');
    --body-800: theme('colors.gray.800');
    --body-700: theme('colors.gray.700');
    --body-600: theme('colors.gray.600');
    --body-500: theme('colors.gray.500');
    --body-400: theme('colors.gray.400');
    --body-300: theme('colors.gray.300');
    --body-200: theme('colors.gray.200');
    --body-100: theme('colors.gray.100');
    --body-50: theme('colors.gray.50');
  }
}
